body {
  font-family: "Permanent Marker", cursive;
  margin: 0;
  background: #aa4b6b;
  background: -webkit-linear-gradient(to right, #3b8d99, #6b6b83, #aa4b6b);
  background: linear-gradient(to right, #3b8d99, #6b6b83, #aa4b6b);
}

#app {
  text-align: center;
}

@media (max-width: 576px) {
  #app {
    height: auto;
  }
}

img {
  max-width: 100%;
  height: auto;
}
